import { React, useState } from 'react';
import { Container, Row, Col, FormGroup, Input, Form } from "reactstrap";
import Helmet from "../components/Helmet/Helmet";
import { send } from 'emailjs-com';
import Swal from 'sweetalert2';
import CommonSection from "../components/UI/CommonSection";

import "../styles/contact.css";

const SERVICE_ID = 'service_2ax3h17';
const TEMPLATE_ID = 'template_jsw02cp';
const USER_ID = 'user_BB2Hzhxg1NSaRKt5qWQtm';

const Contact = () => {
  const [toSend, setToSend] = useState({
    from_name: '',
    message: '',
    reply_to: '',
  });

  const handleOnSubmit = (e) => {
    e.preventDefault();
    send(SERVICE_ID, TEMPLATE_ID, toSend, USER_ID)
      .then(() => {
        Swal({
          icon: 'success',
          title: 'Wiadomość wysłana poprawnie',
        });
      }, (error) => {
        Swal({
          icon: 'error',
          title: 'Nie udało się wysłać wiadomości',
        });
      });
  };
  
  const handleChange = (e) => {
    debugger
    setToSend({ ...toSend, [e.target.name]: e.target.value });
  };
  
  return (
  <Helmet title="Kontakt">
    <CommonSection title="Kontakt" />
    <section>
      <Container>
        <Row>
          <Col lg="7" md="7">
            <h6 className="fw-bold mb-4">Napisz do nas!</h6>

            <Form onSubmit={handleOnSubmit}>
              <FormGroup className="contact__form" name="from_name">
          <Input placeholder="Imię i nazwisko" name="from_name" type="text" onChange={handleChange} required/>
        </FormGroup>
        <FormGroup className="contact__form " name="reply_to">
          <Input placeholder="Adres email" name="reply_to" type="email" onChange={handleChange} required/>
        </FormGroup>
        <FormGroup className="contact__form" name="message">
          <textarea style={{width: '100%', border: '1px solid #ced4da'}}
            rows="5"
            placeholder="Wiadomość..."
            className="textarea"
            name="message"
            onChange={handleChange}
            required
          ></textarea>
        </FormGroup>

              <button className=" contact__btn" type="submit">
                Wyślij wiadomość
              </button>
            </Form>
          </Col>

          <Col lg="5" md="5">
            <div className="contact__info">
              <h6 className="fw-bold">Dane kontaktowe</h6>
              <div className=" d-flex align-items-center gap-2">
                <h6 className="fs-6 mb-0">Adres:</h6>
                <p className="section__description mb-0">ul. Kazimierza Jarząbka 26, 05-500 Piaseczno</p>
              </div>
              <div className=" d-flex align-items-center gap-2">
                <h6 className="fs-6 mb-0">Telefon:</h6>
                <p className="section__description mb-0">+48 600 265 091</p>
              </div>

              <div className=" d-flex align-items-center gap-2">
                <h6 className="mb-0 fs-6">Email:</h6>
                <p className="section__description mb-0">karol.wojewodka@pz-tech.pl</p>
              </div>

            </div>
          </Col>
        </Row>
      </Container>
    </section>
  </Helmet>
  );
};

export default Contact;
