import React from "react";
import { Container, Row, Col } from "reactstrap";
import "../../styles/about-section.css";
import aboutImg from "../../assets/all-images/cars-img/main.png";

const AboutSection = ({ aboutClass }) => {
  return (
    <section
      className="about__section"
      style={
        aboutClass === "aboutPage"
          ? { marginTop: "0px" }
          : { marginTop: "280px" }
      }
    >
      <Container>
        <Row>
          <Col lg="6" md="6">
            <div className="about__section-content">
              <h4 className="section__subtitle">Kilka słów o nas</h4>
              <h2 className="section__title">Witamy, w naszym serwisie</h2>
              <p className="section__description">
              Jesteśmy polską wypożyczalnią samochodów i motocykli turystycznych, z główną siedzibą mieszczącą się w Piasecznie. 
              Dysponujemy, gamą bogato wyposażonych i przygotowanych do drogi pojazdów. Oferujemy wszystkie typy wynajmu, począwszy od najmów krótkoterminowych (nawet na 1 dzień) po wypożyczenia długoterminowe, sięgające aż 2-óch lat. 
              Naszą ofertę kierujemy zarówno do firm, jak i do osób indywidualnych, które w szybki i profesjonalny sposób chcą pozyskać pojazd na doraźne potrzeby, działalność sezonową, bądź na realizację określonych projektów czasowych.
              </p>

              <div className="about__section-item d-flex align-items-center">
                <p className="section__description d-flex align-items-center gap-2">
                  <i class="ri-checkbox-circle-line"></i> Atrakcyjne ceny wynajmu
                </p>

                <p className="section__description d-flex align-items-center gap-2">
                  <i class="ri-checkbox-circle-line"></i> Prestiżowe modele pojazdów
                </p>
              </div>

              <div className="about__section-item d-flex align-items-center">
                <p className="section__description d-flex align-items-center gap-2">
                  <i class="ri-checkbox-circle-line"></i> Prężnie rozwijająca się firma
                </p>

                <p className="section__description d-flex align-items-center gap-2">
                  <i class="ri-checkbox-circle-line"></i> Miła i sprawna obsługa klienta
                </p>
              </div>
            </div>
          </Col>

          <Col lg="6" md="6">
            <div className="about__img">
              <img src={aboutImg} alt="" className="w-100" />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default AboutSection;
