import React from "react";

import { Container, Row, Col, ListGroup, ListGroupItem } from "reactstrap";
import { Link } from "react-router-dom";
import "../../styles/footer.css";

const quickLinks = [
  {
    path: "/",
    display: "O nas",
  },

  {
    path: "/motorcycle",
    display: "Lista motocykli",
  },
  
  {
    path: "/cars",
    display: "Lista samochodów",
  },

  {
    path: "/contact",
    display: "Kontakt",
  },
];

const Footer = () => {
  const date = new Date();
  const year = date.getFullYear();
  return (
    <footer className="footer">
      <Container>
        <Row>
          <Col lg="4" md="4" sm="12">
            <div className="logo footer__logo">
              <h1>
                <Link to="/about" className=" d-flex align-items-center gap-2">
                  <i class="ri-motorbike-line"></i>
                  <span>
                   WMT - wypożyczalnia <br /> motocykli turystycznych
                  </span>
                </Link>
              </h1>
            </div>
            <p className="footer__logo-content">
            Jesteśmy sprawdzoną wypożyczalnią samochodów oraz motycykli turystycznych, z siedzibą w Piasecznie.
            </p>
          </Col>

          <Col lg="2" md="4" sm="6">
            <div className="mb-4">
              <h5 className="footer__link-title">Linki</h5>
              <ListGroup>
                {quickLinks.map((item, index) => (
                  <ListGroupItem key={index} className="p-0 mt-3 quick__link">
                    <Link to={item.path}>{item.display}</Link>
                  </ListGroupItem>
                ))}
              </ListGroup>
            </div>
          </Col>

          <Col lg="3" md="4" sm="6">
            <div className="mb-4">
              <h5 className="footer__link-title mb-4">Nasza siedziba</h5>
              <p className="office__info">05-500 Piaseczno, ul. Kazimierza Jarząbka 26</p>
              <p className="office__info"><i class="ri-phone-line"></i>&nbsp;&nbsp;  +48 600 265 091</p>

              <p className="office__info"><i class="ri-mail-line"></i>&nbsp;&nbsp;  karol.wojewodka@pz-tech.pl</p>

              <p className="office__info"><i class="ri-time-line"></i>&nbsp;&nbsp;  8.00 - 16.00</p>
            </div>
          </Col>

          <Col lg="12">
            <div className="footer__bottom">
              <p className="section__description d-flex align-items-center justify-content-center gap-1 pt-4">
                <i class="ri-copyright-line"></i>WMT - wypożyczalnia motocykli turystycznych, wszelkie 
                prawa zastrzeżone | {year}
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
