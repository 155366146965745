import React from "react";
import { Container, Row, Col } from "reactstrap";
import Helmet from "../components/Helmet/Helmet";
import CommonSection from "../components/UI/CommonSection";
import motorData from "../assets/data/motorData";
import MotorItem from "../components/UI/MotorItem";

const MotorListing = () => {
  return (
    <Helmet title="Motocykle">
      <CommonSection title="Nasze modele motocykli" />

      <section>
        <Container>
          <Row>

            {motorData.map((item) => (
              <MotorItem item={item} key={item.id} />
            ))}
          </Row>
        </Container>
      </section>
    </Helmet>
  );
};

export default MotorListing;
