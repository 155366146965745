// import all images from assets/images directory
import img01 from "../all-images/cars-img/ducati-streetfighter.jpg";
import img02 from "../all-images/cars-img/honda-africa.png";
import img03 from "../all-images/cars-img/drz.jpg";

const motorData = [
  {
    id: 1,
    brand: "Honda",
    rating: 3,
    carName: "Honda Africa Twin",
    imgUrl: img02,
    model: "CRF1100L - 2020",
    price: 250,
    speed: "102 KM",
    gps: "4,9 l/100km",
    seatType: "Heated seats",
    automatic: "1084 cm³",
    description:
      " Honda Africa Twin to motocykl z segmentu Adventure, który zadowoli najbardziej wymagających jeźdźców. Właściwości szosowo-terenowe motocykla idą w parze z nowoczesną technologią m.in. dotykowym ekranem z interfejsem Android Auto oraz opcjonalną dwusprzęgłową przekładnią DCT.",
  },

  {
    id: 2,
    brand: "Suzuki",
    rating: 132,
    carName: "Suzuki DR-Z",
    imgUrl: img03,
    model: "DR-Z 400SM - 2006",
    price: 150,
    speed: "40 KM",
    gps: "4,1 l/100km",
    seatType: "Heated seats",
    automatic: "398 cm³",
    description:
      " DR-Z 400SM jest dobrze znanym od wielu lat motocyklem terenowym, w którym Suzuki dokonało kilku istotnych zmian, aby mógł w pełni aspirować do miana supermoto. SM posiada 17-calowe koła obutych w drogowe opony o mocnym zacięciu sportowym, czyli Dunlopy Sportmax D208. Do sprawnego zatrzymywania (i nie tylko) służy większa tarcza hamulcowa – o średnicy 310 mm. Na precyzję prowadzenia wpływa też nieco sztywniej zestrojone zawieszenie. Przedni, klasyczny zawias teleskopowy zastąpiono w DR-Z 400SM układem odwróconym (upside-down).",
  },

  {
    id: 3,
    brand: "Ducati",
    rating: 4,
    carName: "Ducati Streetfighter",
    imgUrl: img01,
    model: "Streetfighter V4 - 2023",
    price: 310,
    speed: "208 KM",
    gps: "7,6 l/100km",
    seatType: "Heated seats",
    automatic: "1103 cm³",
    description:
      " Ducati Streetfighter V4 2023 wyraża swój sportowy charakter dzięki standardowej konfiguracji jednoosobowej, z dostarczonymi podnóżkami i siedziskiem pasażera. Design Streetfightera V4 jest bezpośredni i minimalistyczny, z przednią lampą LED w kształcie litery V, która przypomina Panigale V4. Niski, pochylony do przodu przód, który przedłuża mocne linie baku, nadaje Streetfighterowi V4 wyglądu drapieżnika. Zbiornik paliwa, o pojemności zwiększonej do 17 litrów, ma taki sam kształt jak w modelu Panigale V4 2022. Został przeprojektowany, aby zapewniać kierowcy lepszą pozycję podczas hamowania i pokonywania zakrętów.",
  },


];

export default motorData;
