import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import About from "../pages/About";
import CarListing from "../pages/CarListing";
import CarDetails from "../pages/CarDetails";
import NotFound from "../pages/NotFound";
import Contact from "../pages/Contact";
import MotorListing from "../pages/MotorListing";
import MotorDetails from "../pages/MotorDetails";

const Routers = () => {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/about" />} />
      <Route path="/about" element={<About />} />
      <Route path="/cars" element={<CarListing />} />
      <Route path="/motorcycle" element={<MotorListing />} />
      <Route path="/motorcycle/:slug" element={<MotorDetails />} />
      <Route path="/cars/:slug" element={<CarDetails />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default Routers;
