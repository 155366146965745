// import all images from assets/images directory
import img01 from "../all-images/cars-img/stinger.png";

const carData = [
  {
    id: 1,
    brand: "Kia",
    rating: 5,
    carName: "Kia Stinger",
    imgUrl: img01,
    model: "Stinger 3.3 GT - 2022",
    price: 600,
    speed: "366 KM",
    gps: "Nawigacja GPS",
    seatType: "Podgrzewane / klimatyzowane siedzenia",
    automatic: "Automat",
    description:
      " Kia Stinger GT to auto stworzone w oparciu o najlepsze wzorce samochodów klasy wyższej z początku XXI wieku. Piękna sylwetka, ponad 3-litrowy silnik V6 (G6DP), duża moc, porządny napęd na cztery koła, a do tego świetne wyposażenie i osiągi czynią z niej reprezentacyjną limuzynę o sportowym zacięciu. W czasach downsizingu, gdy Mercedesy napędzane są 1,3-litrowymi silnikami Renault, Stinger GT wydaje się być idealną alternatywą dla wszystkich tych, którzy oczekują od auta klasy wyższej czegoś więcej niż tylko drogiego loga na masce i gigantycznego ekranu LCD."
  }


];

export default carData;
